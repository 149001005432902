/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2021 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Dunwich Type Founders
 * License URL: https://www.fontspring.com/licenses/dunwich-type-founders/webfont
 *
 *
 */
@font-face {
  font-family: Ironstrike-BlackItalic;
  src: url("Ironstrike-BlackItalic-webfont.09be889d.woff2") format("woff2"), url("Ironstrike-BlackItalic-webfont.77ef9b9c.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Ironstrike-BoldItalic;
  src: url("Ironstrike-BoldItalic-webfont.b5245c66.woff2") format("woff2"), url("Ironstrike-BoldItalic-webfont.0e7ecd9e.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/*# sourceMappingURL=index.06ae8b59.css.map */
